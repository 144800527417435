<template>
  <div class="signup-page">
    <div class="cover">
      <div class="text-center">
        <h2 class="black--text font-30 font-qs-b">Forgot Password?</h2>
      </div>
      <v-form @submit.prevent="submitHandler" v-model="valid">
        <v-text-field
          v-model="user.email"
          type="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <div class="text-right">
          <v-btn
            :disabled="!valid"
            color="secondary"
            type="submit"
            :dark="valid"
          >
            Get Password
          </v-btn>
        </div>
      </v-form>
      <div class="text-center mt-2">
        <span
          >Goback to
          <router-link to="/login" tag="span"><span class="primary2--text cursor-pointer">Login</span></router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    user: {},
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    async submitHandler() {
      const {data} = await this.$axios.post("/user/password/forgot", {
        ...this.user,
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-page {
  background: $primary;
  width: 100%;
  height: 100vh;
  position: relative;

  .cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgb(160, 160, 160);
    padding: 15px;
    min-width: 30%;
  }
}
</style>
